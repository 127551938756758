import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby-plugin-locale';
import { usePrismicPreview } from 'gatsby-source-prismic';
import React, { useEffect } from 'react';

const pathResolver = ({ node }: any) => () => {
	if (node.type === 'tool') {
		return `/tool/${node.uid}`;
	} else if (node.type === 'homepage' || node.type === 'category') {
		return '/';
	}
	return `${node.uid}`;
};

// Note that the `location` prop is taken and provided to the `usePrismicPreview` hook.
const PreviewPage = () => {
	// Let's use a static query to retrieve all known paths. We'll use it later
	// to navigate to the unpublishedPreview page if the document is not
	// published.
	const { allSitePage } = useStaticQuery(graphql`
		query allPaths {
			allSitePage {
				nodes {
					path
				}
			}
		}
	`);

	const allPaths = allSitePage?.nodes?.map((node: any) => node.path);

	const { isPreview, previewData, path } = usePrismicPreview({
		repositoryName: 'facilitatie-tools',
		linkResolver: pathResolver,
		pathResolver,
	});

	// This useEffect runs when values from usePrismicPreview update. When
	// preview data is available, this will save the data globally and redirect to
	// the previewed document's page.
	useEffect(() => {
		// If this is not a preview, skip.
		//   null = Not yet determined if previewing.
		//   true = Preview is available.
		//   false = Preview is not available.
		if (isPreview == null || !previewData || !path) {
			return;
		}

		if (isPreview === false) {
			navigate('/');
			return;
		}

		// Save the preview data to somewhere globally accessible. This could be
		// something like a global Redux store or React context.
		//
		// We'll just put it on window.
		window.__PRISMIC_PREVIEW_DATA__ = previewData;

		// Navigate to the document's page.
		let navigateTo = path;

		if (!allPaths.includes(path)) {
			navigateTo = '/unpublishedPreview';
		}

		navigate(navigateTo, { state: { isPreview: true } });
	}, [isPreview, previewData, path]);

	// Tell the user if this is not a preview.
	if (isPreview === false) return null;

	return <div>Loading preview...</div>;
};

export default PreviewPage;
